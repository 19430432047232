import "core-js/features/promise";
import "core-js/features/map";
import "core-js/features/array/find";
import "core-js/features/array/includes";
import "core-js/features/array/find-index";
import "core-js/features/string/match-all";
import "core-js/features/symbol/iterator";
import "core-js/modules/es.object.lookup-getter";
import "core-js/modules/es.set";
import "./polyfill/Now";


(function () {
    "use strict";
    let win = window as any;
    var lastTime = 0,
        vendors = ['ms', 'moz', 'webkit', 'o'],
        x;


    if (!window.requestAnimationFrame) {
        win.requestAnimationFrame = function (callback: any, element: any) {
            var currTime = new Date().getTime(),
                timeToCall = Math.max(0, 16 - (currTime - lastTime)),
                id = window.setTimeout(function () {
                    callback(currTime + timeToCall);
                }, timeToCall);
            lastTime = currTime + timeToCall;
            return id;
        };
    }

    if (!win.cancelAnimationFrame) {
        win.cancelAnimationFrame = function (id: any) {
            win.clearTimeout(id);
        };
    }
}());

//import "regenerator-runtime/runtime";

(async () => {
    await (() => import(/* webpackChunkName: "outer" */ './OnlineChatMain'))();
})();